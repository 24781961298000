<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content">
      <h2 class="title"><span class="title_icon"></span>{{ isEdit ? "编辑" : "添加" }}业务</h2>
      <div class="Wrapper">
        <div class="section-title">基础信息</div>
        <el-form
          label-position="right"
          label-width="140px"
          :model="formInline"
          :rules="rules"
          ref="form"
        >
          <el-form-item :label="$t('searchModule.Parking_card_name')" prop="parkCardRuleId">
            <el-select
              :disabled="isEdit"
              filterable
              v-model="formInline.parkCardRuleId"
              placeholder="请选择"
              @change="parkCardRuleIdChange"
            >
              <el-option
                :label="item.name"
                :value="item.parkCardRuleId"
                :key="item.parkCardRuleId"
                v-for="item in ruleList"
              ></el-option>
            </el-select>
            <div class="range-wrapper" v-if="formInline.parkCardRuleId">
              <div>适用范围：{{ formInline.useRangeDesc }}</div>
              <div v-if="formInline.effectType === 0">
                有效范围：{{ formInline.effectStartDate }}至{{ formInline.effectEndDate }}
              </div>
              <div v-if="formInline.effectType === 1">
                <div style="color: #606266">
                  自购买后<span style="color: #409eff; font-size: 20px">
                    {{ formInline.effectDaysAfterBuy }}天 </span
                  >开始生效,有效天数<span style="color: #409eff; font-size: 20px">
                    {{ formInline.validityPeriod }} </span
                  >天
                </div>
              </div>
              <div>车位限制：{{ formInline.carMaxNum }}</div>
            </div>
          </el-form-item>

          <!-- :rules="[{required: true, message: '请选择车牌号码', trigger: 'change'},
                  {validator: checkNumber, trigger: 'blur'}]" -->
          <!-- :prop="'numberRuleActivities.' + ind + '.numberId' &&
            'numberRuleActivities.' + ind + '.plateNumber' &&
            'numberRuleActivities.' + ind + '.plateColor'" -->
          <!-- :class="{marginSty: ind !== formInline.numberRuleActivities.length - 1}" -->
          <el-form-item
            label="车牌号码"
            :required="true"
            :key="ind"
            v-for="(val, ind) in formInline.numberRuleActivities"
          >
            <el-form-item
              :prop="'numberRuleActivities.' + ind + '.numberName'"
              :rules="[
                {
                  required: true,
                  message: '请选择车牌号码',
                  trigger: 'change',
                },
              ]"
              style="width: 100px; display: inline-block"
            >
              <el-select
                v-model="val.numberName"
                placeholder="请选择"
                @change="changePlate1(val, val.numberName, ind)"
              >
                <el-option
                  v-for="item in platerNumberLists"
                  :key="item.numberName"
                  :label="item.numberName"
                  :value="item.numberName"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :prop="'numberRuleActivities.' + ind + '.plateNumber'"
              :rules="[
                {
                  required: true,
                  message: '请输入车牌号码',
                  trigger: 'change',
                },
                { required: true, validator: checkNumber, trigger: 'change' },
              ]"
              style="width: 210px; padding: 0 20px 0 5px; display: inline-block"
            >
              <el-input
                :maxlength="7"
                v-model.trim="val.plateNumber"
                placeholder="请输入车牌号"
              ></el-input>
            </el-form-item>
            车牌颜色
            <el-form-item
              :prop="'numberRuleActivities.' + ind + '.plateColor'"
              :rules="[
                {
                  required: true,
                  message: '请选择车牌颜色',
                  trigger: 'change',
                },
              ]"
              style="width: 100px; display: inline-block"
            >
              <el-select
                v-model.trim="val.plateColor"
                placeholder="请选择"
                @change="changePlate(val, val.plateColor, ind)"
              >
                <el-option
                  :label="v.desc"
                  :value="v.code"
                  v-for="v in colorList"
                  :key="v.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-button class="button-style" v-if="ind == 0" type="primary" plain @click="addNumber">
              <i class="el-icon-plus icon-style"></i>
            </el-button>
            <el-button class="button-style" v-else type="warning" plain @click="deleteNumber(ind)">
              <i class="el-icon-minus icon-style"></i>
            </el-button>
          </el-form-item>
          <el-form-item :label="$t('searchModule.phone_number')" prop="phoneNumber">
            <el-input
              style="width: 217px"
              :maxlength="11"
              v-model.trim="formInline.phoneNumber"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.remarks')">
            <el-input
              :maxlength="50"
              type="textarea"
              v-model.trim="formInline.remark"
              placeholder="输入内容"
              style="width: 350px"
            ></el-input>
          </el-form-item>
          <div class="section-title">销售信息</div>
          <!-- isEdit==true -->
          <el-form-item v-if="isEdit" label="当前有效期截止">
            {{ formInline.endDateDesc }}
          </el-form-item>
          <el-form-item label="续费有效期" prop="" v-if="isEdit">
            <el-input
              style="width: 150px"
              :maxlength="6"
              v-model.trim="formInline.expirationDate1"
              placeholder="请输入"
            ></el-input
            ><span> {{ priceCycleDesc }}</span>
          </el-form-item>

          <!-- isEdit==false -->
          <el-form-item label="购买有效期" prop="expirationDate" v-if="!isEdit">
            <el-input
              style="width: 150px"
              :disabled="isEdit"
              :maxlength="6"
              v-model.trim="formInline.expirationDate"
              placeholder="请输入"
            ></el-input>
            <span> {{ priceCycleDesc }}</span
            ><span v-if="!isEdit">（{{ priceTime }}天）</span>
          </el-form-item>
          <el-form-item label="生效起始日" prop="startDate" v-if="!isEdit">
            <el-date-picker
              v-model="formInline.startDate"
              type="date"
              placeholder="选择日期"
              :pickerOptions="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item :label="isEdit ? '续费后有效期截止' : '有效期截止'">
            {{ expirationData }}
          </el-form-item>
          <el-form-item :label="isEdit ? '续费价格' : '价格'">
            <el-input
              :disabled="true"
              style="width: 150px"
              v-model.trim="totalMoney"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item :label="$t('searchModule.remarks')">
            <el-input
              :maxlength="50"
              type="textarea"
              v-model.trim="formInline.remark"
              placeholder="输入内容"
              style="width: 350px"
            ></el-input>
          </el-form-item> -->
        </el-form>
      </div>
      <div style="text-align: left; margin-left: 8.5%">
        <el-button type="primary" @click="addEdit1" style="width: 88px" v-if="!isEdit"
          >{{ $t('button.preservation') }}</el-button
        >
        <el-button type="primary" @click="addEdit" style="width: 88px" v-if="isEdit"
          >{{ $t('button.preservation') }}</el-button
        >
        <el-button type="" @click="$router.go(-1)" style="width: 88px">取消</el-button>
      </div>
      <div class="section-title" v-if="isEdit">停车卡业务</div>
      <div class="tableWrapper bgFFF paddingB10" v-if="isEdit">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          stripe
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>
          <el-table-column
            :show-overflow-tooltip="item.prop == 'notes'"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <el-table-column
            label="操作"
            header-align="center"
            align="center"
            width="100"
            v-if="isEdit"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="refund(scope.row)" v-if="scope.row._isFund"
                >退款</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper" v-show="total > 0">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="停车卡业务退款"
      @click="closeDialog"
      :visible.sync="dialogTableVisible"
      :close-on-click-modal="false"
      style="width: 700px; margin-left: calc(50% - 350px); margin-top: 5%"
    >
      <el-form :model="formT" :rules="rules" ref="formT">
        <el-form-item label="退款金额(元)" prop="money">
          <el-input
            size="12"
            :maxlength="8"
            v-model.trim="formT.money"
            placeholder="退款金额"
          ></el-input>
        </el-form-item>
        <div style="margin-bottom: 30px; font-size: 12px">
          最多可退<span style="color: red; font-size: 10px">
            {{ (maxRefund / 100).toFixed(2) }} </span
          >元
        </div>
        <el-form-item label="退款后有效期截止" prop="refoundTime">
          <!-- <el-time-picker
            v-model="refoundTime"
            placeholder="请选择时间"
            :disabled="refoundDisabled"
          >
          </el-time-picker> -->
          <el-date-picker
            v-model="refoundTime"
            type="date"
            placeholder="选择日期"
            :disabled="refoundDisabled"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
          <el-checkbox v-model="refoundDisabled" class="checkedfalse" @change.stop="changeRefound"
            >失效</el-checkbox
          >
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button type="primary" @click="refound">确认退款</el-button
        ><el-button style="margin-left: 40px" @click="closeDialog">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public";
export default {
  name: "parkCardNewAdd",
  data() {
    const originalPriceRule = (rule, value, callback) => {
      if (value <= 0) {
        callback(new Error("请输入大于0的数字,最多两位小数"));
      } else if (!/^[0-9]+(.[0-9]{1,2})?$/.test(value)) {
        callback(new Error("请输入大于0的数字,最多两位小数"));
      } else {
        callback();
      }
    };
    let checkPhone = (rule, value, callback) => {
      if (value && !/^\d{11}$/.test(value)) {
        callback("手机号格式不正确");
        return false;
      } else {
        return callback();
      }
    };
    return {
      pickerOptions: {
        disabledDate(val) {
          return val.getTime() <= new Date().getTime() - 8.64e7;
        },
      },
      // expirationData:'',
      parkCardIsActive: true,
      isNowDate: true,
      ruleStartTime: "",
      ruleList: [],
      isEdit: false,
      loading: false,
      scopeClick: "",
      scopeDesc: "",
      operationId: "",
      formT: {
        money: "",
      },
      refoundDisabled: true,
      formInline: {
        parkCardRuleId: "",
        numberRuleActivities: [
          {
            numberId: "",
            plateNumber: "",
            plateColor: "",
          },
        ],
        phoneNumber: "",
        useRangeDesc: "",
        startDate: new Date().getTime(),
        totalMoney: "",
        desc: "",
        expirationDate1: "",
        remark: "",
        endDateDesc: "",
      },
      colorList: [],
      dayTimeList: { 1: 1, 2: 31, 3: 93, 4: 186, 5: 365 }, // 用于计算天数
      platerNumberLists: [
        {
          numberId: "11",
          numberName: "京",
        },
        {
          numberId: "12",
          numberName: "津",
        },
        {
          numberId: "13",
          numberName: "冀",
        },
        {
          numberId: "14",
          numberName: "晋",
        },
        {
          numberId: "15",
          numberName: "蒙",
        },
        {
          numberId: "21",
          numberName: "辽",
        },
        {
          numberId: "22",
          numberName: "吉",
        },
        {
          numberId: "23",
          numberName: "黑",
        },
        {
          numberId: "31",
          numberName: "沪",
        },
        {
          numberId: "32",
          numberName: "苏",
        },
        {
          numberId: "33",
          numberName: "浙",
        },
        {
          numberId: "34",
          numberName: "皖",
        },
        {
          numberId: "35",
          numberName: "闽",
        },
        {
          numberId: "36",
          numberName: "赣",
        },
        {
          numberId: "37",
          numberName: "鲁",
        },
        {
          numberId: "41",
          numberName: "豫",
        },
        {
          numberId: "42",
          numberName: "鄂",
        },
        {
          numberId: "43",
          numberName: "湘",
        },
        {
          numberId: "44",
          numberName: "粤",
        },
        {
          numberId: "45",
          numberName: "桂",
        },
        {
          numberId: "46",
          numberName: "琼",
        },
        {
          numberId: "50",
          numberName: "渝",
        },
        {
          numberId: "51",
          numberName: "川",
        },
        {
          numberId: "52",
          numberName: "黔",
        },
        {
          numberId: "53",
          numberName: "滇",
        },
        {
          numberId: "54",
          numberName: "藏",
        },
        {
          numberId: "61",
          numberName: "陕",
        },
        {
          numberId: "62",
          numberName: "甘",
        },
        {
          numberId: "63",
          numberName: "青",
        },
        {
          numberId: "64",
          numberName: "宁",
        },
        {
          numberId: "65",
          numberName: "新",
        },
        {
          numberId: "71",
          numberName: "台",
        },
        {
          numberId: "81",
          numberName: "港",
        },
        {
          numberId: "82",
          numberName: "澳",
        },
        {
          numberId: "90",
          numberName: "外",
        },
      ],
      rules: {
        parkCardRuleId: [
          {
            required: true,
            message: "必填",
            trigger: "change",
          },
        ],
        phoneNumber: [{ validator: checkPhone, trigger: "blur" }],
        expirationDate: [{ required: true, message: "必填", trigger: "blur" }],
        expirationDate1: [{ required: true, message: "必填", trigger: "blur" }],
        startDate: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        money: [
          {
            required: true,
            message: "请输入金额",
            trigger: "change",
          },
          {
            validator: originalPriceRule,
            trigger: "blur",
          },
        ],
        // totalMoney: [{ required: true, message: "必填", trigger: "blur" }],
      },
      tableData: [],
      tableCols: [
        {
          prop: "payTime",
          label: this.$t("list.payment_time"),
          width: "",
          formatter: (r, c, v) => {
            return v ? dateFormat(new Date(Number(v))) : "";
          },
        },
        {
          prop: "buyCycle",
          label: "购买周期数",
          width: "",
        },
        {
          prop: "endDate",
          label: "有效期截止",
          width: "",
          formatter: (r, c, v) => {
            if (r.state == 5) {
              return "-";
            } else {
              return v ? dateFormat(new Date(Number(v)), "yyyy-MM-dd") : "";
            }
          },
        },

        {
          prop: "money",
          label: this.$t("list.Pay_amount"),
          width: "",
          formatter: (r, c, v) => {
            return v ? v / 100 + "元" : 0;
          },
        },
        {
          prop: "buyTypeDesc",
          label: "购买渠道",
          width: "",
        },
        {
          prop: "tradeNo",
          label: "渠道流水号",
          width: "",
        },
        // {
        //   prop: "remark",
        //   label: "备注",
        //   width: "",
        // },
      ],
      total: 0,
      pageSize: 15,
      page: 1,
      dialogTableVisible: false,
      refoundMoney: "", // 退款金额
      refoundTime: "", // 退款时间
      totalMoney: "",
      orderId: "", // 用于保存点击退款的时候，对应数据的orderId
      maxRefund: "", // 用于点击退款时，最大退款金额
    };
  },
  methods: {
    parkCardRuleIdChange() {
      let parksName = [];
      let parks = null;
      this.formInline.numberRuleActivities.splice(1);
      this.ruleList.forEach((v) => {
        if (v.parkCardRuleId == this.formInline.parkCardRuleId) {
          if (v.scope == 1 && v.scopeDesc === "全部车场") {
            this.getScopes(v.operatorId);
          }
          parks = v.parks;
          this.formInline.validityPeriod = v.validityPeriod;
          // this.ruleStartTime = v.effectStartTime;
          this.scopeClick = v.scope;
          this.scopeDesc = v.scopeDesc;
          this.operationId = v.operationId;
          this.formInline.carMaxNum = v.carMaxNum;
          this.formInline.effectType = v.effectType;
          this.formInline.effectDaysAfterBuy = v.effectDaysAfterBuy;
          this.formInline.effectStartDate = this.$moment(parseInt(v.effectStartTime)).format(
            "YYYY-MM-DD"
          );
          this.formInline.effectEndDate = this.$moment(parseInt(v.effectEndTime)).format(
            "YYYY-MM-DD"
          );
        }
      });
      if (this.scopeClick == 2) {
        parks.forEach((v) => {
          parksName.push(v.parkName);
          this.formInline.useRangeDesc = parksName.toString();
        });
      } else {
        // 全部车场
        // parksName.push(this.scopeDesc);
        // this.getScopes(this.operatorId);
      }
    },
    changeRefound() {
      console.log(this.refoundDisabled);
      this.refoundDisabled = !this.refoundDisabled;
      console.log(this.refoundDisabled);
    },
    // 获取全部车场名称
    getScopes(operationId) {
      const opt = {
        url: "/acb/2.0/park/currentUserParkList",
        method: "get",
        data: {
          operationId: operationId,
          dataSource: 0,
        },
        success: (res) => {
          let arr = [];
          res.value.forEach((item) => {
            arr.push(item.parkName);
          });
          this.formInline.useRangeDesc = arr.join("、");
        },
      };
      this.$request(opt);
    },
    refund(val) {
      this.dialogTableVisible = true;
      this.orderId = val.orderId;
      this.maxRefund = val.maxRefund;
      console.log(val, "val-----------");
    },
    checkNumber(rule, value, callback) {
      let rel = "";
      this.formInline.numberRuleActivities.forEach((el) => {
        if (value == el.plateNumber && (el.numberId == 0 || el.numberId == 1)) {
          // 蓝牌 黄牌
          rel = /^[A-Z]{1}[A-Z0-9]{5}$/;
          if (rel.test(value)) {
            callback();
          } else {
            callback("请输入正确的车牌号");
          }
        } else if (value == el.plateNumber && (el.numberId == 2 || el.numberId == 3)) {
          console.log("23", el);
          // 2绿牌、3黄绿牌
          rel = /^[A-Z]{1}[A-Z0-9]{6}$/;
          if (rel.test(value)) {
            callback();
          } else {
            callback("请输入正确的车牌号");
          }
        } else if (value == el.plateNumber && (el.numberId == 4 || el.numberId == 5)) {
          console.log("45", el);
          // 4黑牌、5白牌
          rel = /^[A-Z]{1}[A-Z0-9]{5,6}$/;
          if (rel.test(value)) {
            callback();
          } else {
            callback("请输入正确的车牌号");
          }
        } else {
          callback();
        }
      });
      // if (value !== "") {
      //   callback(new Error('车牌号不能为空'));
      // }
    },
    changePlate1(val, numberName, i) {
      // val.numberId = numberName;
      val.numberName = numberName;
      this.$set(this.formInline.numberRuleActivities[i], "numberName", numberName);
      console.log(val, numberName, i, this.formInline.numberRuleActivities, "999999999999");
    },
    changePlate(val, color, i) {
      console.log("hahah", color);
      val.numberId = color;
      val.plateColor = color;
      val.plateColorCode = color;
      // console.log(this.formInline.numberRuleActivities, "888888888888");
    },
    closeDialog() {
      this.dialogTableVisible = false;
      this.refoundMoney = "";
      this.refoundTime = "";
    },
    addNumber() {
      if (this.formInline.numberRuleActivities.length < this.formInline.carMaxNum) {
        this.formInline.numberRuleActivities.push({
          numberId: "",
          plateNumber: "",
          plateColor: "",
        });
      } else {
        if (this.formInline.carMaxNum) {
          this.$message({
            type: "info",
            message: "最多添加" + this.formInline.carMaxNum + "辆",
          });
        } else {
          this.formInline.numberRuleActivities.push({
            numberId: "",
            plateNumber: "",
            plateColor: "",
          });
        }
      }
    },
    deleteNumber(ind) {
      let numberRuleActivities = this.formInline.numberRuleActivities;
      numberRuleActivities.splice(ind, 1);
    },

    getColor() {
      this.$axios.get("/acb/2.0/specialplate/plateColor/list").then((res) => {
        if (res.state == 0) {
          this.colorList = res.value;
          console.log(this.colorList, "this.colorList");
        } else {
          this.$alert(res.desc, "提示");
        }
      });
    },
    // 修改页面获取详情
    getDetail() {
      this.$axios
        .get(`/acb/2.0/parkCard/detail`, {
          data: {
            parkCardId: this.$route.query.parkCardId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            let list = res.value;
            // this.formInline = res.value;
            // console.log(list, "---------list");
            let createdTime = list.createdTime.substring(0, 10);
            list.numberRuleActivities = res.value.plateList;
            list.endDateDesc = res.value.endDateDesc.slice(0, 10);
            list.numberRuleActivities.forEach((item) => {
              item.numberName = item.plateNumber[0];
              item.plateNumber = item.plateNumber.slice(1);
            });
            // console.log(list.numberRuleActivities, "list.numberRuleActivities");
            let newDate = dateFormat(new Date(), "yyyy-MM-dd");
            this.isNowDate = createdTime === newDate;
            // 查看停车卡是否已生效
            this.parkCardIsActive = list.state == 1;
            list.plateColor = res.value.plateColorCode;
            this.parkCardRuleIdChange(list.parkCardRuleId);
            this.formInline = list;
          } else {
            this.$alert(res.desc);
          }
        });
    },
    // 获取停车卡名称列表
    getRulelist() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/acb/2.0/parkCardRule/list", {
            data: {
              page: 0,
              pageSize: 0,
              state: 2,
            },
          })
          .then((res) => {
            let ruleList = res && res.value.list;
            if (this.isEdit) {
              let flag = false;
              for (let i = 0; i < ruleList.length; i++) {
                if (ruleList[i].parkCardRuleId == this.$route.query.parkCardRuleId) {
                  flag = true;
                }
              }
              // setTimeout(() => {
              //   if (!flag) {
              //     ruleList.push({
              //       parkCardRuleId: this.$route.query.parkCardRuleId,
              //       name: this.$route.query.parkCardTitle,
              //     });
              //   }
              // }, 100);
            }
            this.ruleList = ruleList;
            resolve();
          });
      });
    },
    addEdit1() {
      let plateArr = [];

      this.formInline.numberRuleActivities.forEach((val) => {
        if (this.isEdit) {
          plateArr.push({
            plateColor: val.plateColorCode,
            plateNumber: val.numberName + val.plateNumber,
            carId: val.carId,
          });
        } else {
          plateArr.push({
            plateColor: val.plateColor,
            plateNumber: val.numberName + val.plateNumber,
          });
        }
      });
      const data = {
        plateList: plateArr,
        parkCardRuleId: this.formInline.parkCardRuleId,
      };
      // console.log(data, "data---5555555555555");
      this.$refs.form.validate((valid) => {
        // console.log(valid, "valid------");
        if (valid) {
          let find = false;
          let arr = this.formInline.numberRuleActivities;
          for (var i = 0; i < arr.length; i++) {
            for (var j = i + 1; j < arr.length; j++) {
              if (
                arr[i].plateColor == arr[j].plateColor &&
                arr[i].numberName == arr[j].numberName &&
                arr[i].plateNumber == arr[j].plateNumber
              ) {
                find = true;
                break;
              }
            }
            if (find) break;
          }
          if (find) {
            this.$message({
              type: "warning",
              message: "输入了相同的车牌",
            });
            return;
          }
          let url = "/acb/2.0/parkCard/checkExist";
          let method = "post";
          const opt = {
            url,
            method,
            config: {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            },
            data,
            success: (res) => {
              if (res.state == 0) {
                if (res.value) {
                  this.$confirm(
                    `车牌号{${res.value}}已存在正在生效的月卡订单，是否确定新建订单？点击确定后新建订单`,
                    this.$t('pop_up.Tips'),
                    {
                      confirmButtonText: this.$t('pop_up.Determine'),
                      cancelButtonText: "取消",
                      type: "warning",
                      showCancelButton: true, // 是否显示取消按钮
                      showClose: true, // 是否显示右上角的x
                      closeOnClickModal: true, // 是否可以点击空白处关闭弹窗
                    }
                  )
                    .then(() => {
                      this.addEdit();
                    })
                    .catch(() => {});
                } else {
                  this.addEdit();
                }
              } else {
                this.$alert(res.desc, "提示");
              }
            },
            fail: (err) => {},
          };
          this.$request(opt);
        }
      });
    },
    addEdit() {
      // console.log(this.formInline.startDate, "this.formInline.startDate");
      // console.log(dateFormat(this.formInline.startDate, "yyyy-MM-dd"));
      // console.log(this.expirationData, "this.expirationData");
      let url = this.isEdit ? "/acb/2.0/parkCard/updateNew" : "/acb/2.0/parkCard/add";
      let plateArr = [];
      // console.log(
      //   this.formInline.numberRuleActivities,
      //   "this.formInline.numberRuleActivities--------"
      // );
      // this.colorList
      this.formInline.numberRuleActivities.forEach((val) => {
        if (this.isEdit) {
          plateArr.push({
            plateColor: this.isEdit ? val.plateColorCode : val.plateColor,
            plateNumber: val.numberName + val.plateNumber,
            carId: val.carId,
          });
        } else {
          plateArr.push({
            plateColor: this.isEdit ? val.plateColorCode : val.plateColor,
            plateNumber: val.numberName + val.plateNumber,
          });
        }
      });
      const data = {
        scope: this.formInline.scope,
        // plateNumber: this.formInline.plateNumber,
        // plateColor: this.formInline.plateColor,
        plateList: plateArr,
        phoneNumber: this.formInline.phoneNumber,
        parkCardRuleId: this.formInline.parkCardRuleId,
      };
      let startTime = dateFormat(new Date(this.formInline.startDate), "yyyy-MM-dd") + " 00:00:00";

      if (!this.isEdit) {
        data.buyCycle = Number(this.formInline.expirationDate);
        data.startTime = new Date(startTime).getTime();
        data.endTime = new Date(this.expirationData + " 00:00:00").getTime();
        data.remark = this.formInline.remark;
      }
      // 修改
      if (this.isEdit) {
        data.parkCardId = this.$route.query.parkCardId;
        data.buyCycle = Number(this.formInline.expirationDate1);
        data.remark = this.formInline.remark;
      }
      console.log(data, "data---222222");
      // console.log(this.$refs.form.validate());
      this.$refs.form.validate((valid) => {
        // console.log(valid, "valid------");
        if (valid) {
          let find = false;
          let arr = this.formInline.numberRuleActivities;
          for (var i = 0; i < arr.length; i++) {
            for (var j = i + 1; j < arr.length; j++) {
              if (
                arr[i].plateColor == arr[j].plateColor &&
                arr[i].numberName == arr[j].numberName &&
                arr[i].plateNumber == arr[j].plateNumber
              ) {
                find = true;
                break;
              }
            }
            if (find) break;
          }
          if (find) {
            this.$message({
              type: "warning",
              message: "输入了相同的车牌",
            });
            return;
          }
          let method = this.isEdit ? "post" : "post";
          const opt = {
            url,
            method,
            config: {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            },
            data,
            success: (res) => {
              if (res.state == 0) {
                this.$router.go(-1);
                this.$message({
                  type: "success",
                  message: res.desc,
                });
              } else {
                this.$alert(res.desc, "提示");
              }
            },
            fail: (err) => {},
          };
          this.$request(opt);
        }
      });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 获取历史订单列表
    getNewOrder() {
      this.$axios
        .get(`/acb/2.0/parkCard/renewalOrder`, {
          data: {
            originalOrderId: this.$route.query.orderId,
          },
        })
        .then((res) => {
          this.tableData = res.value;
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].state != 5) {
              this.tableData[i]._isFund = true;
              break;
            }
          }
          // console.log(res, "res---------");
        });
    },
    // 退款操作
    refound() {
      this.$refs.formT.validate((valid) => {
        if (valid) {
          //     if ((this.formT.money * 100) > this.availableRefundMoney || this.formT.money <= 0) {
          //     this.$alert("不能超过建议金额", this.$t('pop_up.Tips'), {
          //         confirmButtonText: this.$t('pop_up.Determine'),
          //     });
          //     // eslint-disable-next-line no-useless-return
          //     return;
          //  }
          // console.log(
          //   this.refoundTime,
          //   !this.refoundTime,
          //   this.refoundDisabled,
          //   "tishi"
          // );
          if (!this.refoundTime && !this.refoundDisabled) {
            this.$alert("请选择退款时间", "提示");
            return 0;
          }
          this.refoundTime = this.refoundTime.replace("00:00:00", "23:59:59");
          let data = {
            endTime: new Date(this.refoundTime).getTime(),
            parkCardOrderId: this.orderId,
            refundMoney: parseInt(this.formT.money * 100),
            isExpire: 0,
          };
          if (this.refoundDisabled) {
            data.endTime = "";
            data.isExpire = 1;
          }
          // console.log(data, "data------123");
          // console.log(new Date(data.endTime).getTime(), "data-----4355");
          this.$axios.post("/acb/2.0/parkCardOrder/submitRefund", { data }).then((res) => {
            if (res.state == 0) {
              this.dialogTableVisible = false;
              this.formT.money = "";
              this.getNewOrder();
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
        }
      });
    },
  },
  computed: {
    expirationData() {
      // console.log(
      //   this.formInline.startDate,
      //   new Date(Number(this.formInline.startDate)).getTime(),
      //   this.formInline.expirationDate,
      //   this.formInline.expirationDate1,
      //   "0000000000123"
      // );
      if (!this.isEdit) {
        let data =
          new Date(this.formInline.startDate).getTime() +
          (this.formInline.expirationDate || 0) * this.priceTime * 24 * 60 * 60 * 1000;
        let data1 = dateFormat(new Date(data), "yyyy-MM-dd");
        // console.log(data1, "data1-------");
        return data1;
      } else {
        // console.log(
        //   this.formInline.endDateDesc,
        //   this.formInline.expirationDate1,
        //   this.priceTime,
        //   "this.formInline.endDateDesc"
        // );
        let data =
          new Date(this.formInline.endDateDesc).getTime() +
          (this.formInline.expirationDate1 || 0) * this.priceTime * 24 * 60 * 60 * 1000;

        // console.log(
        //   data,
        //   new Date(this.formInline.endDateDesc).getTime(),
        //   this.priceTime,
        //   "data----------123123"
        // );
        let data1 = dateFormat(new Date(data), "yyyy-MM-dd");
        // console.log(data1, "data1-------");
        return data1;
      }
    },
    // 购买有效期的日期单位
    priceCycleDesc() {
      // console.log(this.ruleList, "this.ruleList12989888888888888309");

      // console.log(this.ruleList, "7777777777");
      let obj = this.ruleList.find((obj) => obj.parkCardRuleId === this.formInline.parkCardRuleId);
      // console.log(obj, "obj");
      if (obj) {
        return obj ? obj.priceCycleDesc : "月";
      } else {
        return "月";
      }
    },
    // // 计算天数
    // priceCycle() {
    //   let obj = this.ruleList.find(
    //     (obj) => obj.parkCardRuleId === this.formInline.parkCardRuleId
    //   );
    //   // console.log(obj, "obj");
    //   if (obj) {
    //     return obj ? obj.priceCycle : 31;
    //   } else {
    //     return 31;
    //   }
    // },
    // 购买有效期的时间
    priceTime() {
      // let list = { 天: 1, 月: 31, 季: 93, 半年: 186, 年: 365 };
      let list = { 1: 1, 2: 31, 3: 93, 4: 186, 5: 365 };
      if (this.ruleList && this.ruleList.length > 0) {
        // console.log(
        //   this.ruleList,
        //   this.formInline.parkCardRuleId,
        //   "this.ruleList---------"
        // );
        let obj = this.ruleList.find(
          (obj) => obj.parkCardRuleId === this.formInline.parkCardRuleId
        );
        // console.log(obj, "obj--------");
        return obj ? list[obj.priceCycle] : 31;
      } else {
        return 31;
      }
    },
    // 计算价格
    // totalMoney() {
    //   console.log(this.ruleList, "66666666666666");
    //   let obj = this.ruleList.find(
    //     (obj) => obj.parkCardRuleId === this.formInline.parkCardRuleId
    //   );
    //   console.log(
    //     obj,
    //     this.formInline.expirationDate1,
    //     this.formInline.actualAmount,
    //     "计算价格"
    //   );
    //   if (obj) {
    //     return (
    //       (this.formInline.actualAmount *
    //         (this.formInline.expirationDate1 || 1)) /
    //       100
    //     ).toFixed(2);
    //   } else {
    //     return "0.00";
    //   }
    // },
  },
  watch: {
    "formInline.expirationDate": function (newVal, oldVal) {
      let obj = this.ruleList.find((obj) => obj.parkCardRuleId === this.formInline.parkCardRuleId);
      // console.log(obj);
      // console.log(newVal, "---------", obj, obj.actualAmount);
      this.totalMoney = (newVal * obj.actualAmount) / 100 || "";
    },
    "formInline.expirationDate1": function (newVal, oldVal) {
      let obj = this.ruleList.find((obj) => obj.parkCardRuleId === this.formInline.parkCardRuleId);
      // console.log(obj);
      // console.log(newVal, "---------", obj, obj.actualAmount);
      this.totalMoney = (newVal * obj.actualAmount) / 100 || "";
    },
  },
  components: {},
  created() {
    // 获取车牌颜色
    this.getColor();
    // 获取停车卡名称列表
    this.getRulelist().then(() => {
      if (this.$route.query.parkCardId) {
        this.isEdit = true;
        this.getDetail();
      }
    });
    if (this.$route.query.orderId) {
      this.getNewOrder();
    }
  },
  mounted() {
    console.log(this.$route.query, "--------------");
    if (this.$route.query.isEdit == "true") {
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }
    // this.isEdit = this.$route.query.isEdit == "true" ? true : false;
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.Wrapper {
  overflow: hidden;
}

.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;

  .range-wrapper {
    line-height: 20px;
    padding: 10px;
    background: #ecf5ff;
    margin-top: 10px;
    border-radius: 8px;
    min-width: 300px;
  }

  .button-style {
    padding: 4px 10px;
    margin-left: 5px;
  }

  .icon-style {
    font-size: 30px;
    vertical-align: middle;
  }
}

.section-title {
  line-height: 35px;
  background-color: #ccc;
  color: #656565;
  margin: 10px 0;
  font-size: 14px;
  padding-left: 20px;
}

.btn {
  text-align: center;
}

.checkedfalse {
  margin-left: 20px;
}

/deep/ .checkedfalse .el-checkbox__input .el-checkbox__inner {
  // background: #409EFF;
  border: 1px solid #DCDFE5;
  border-radius: 50%; // 对边框进行调整
}

/deep/ .checkedfalse .el-checkbox__input .el-checkbox__inner::after {
  border-color: #ffffff; // 里面内容的颜色
  border-width: 2px; // 对里面的对号做一个加粗
}
</style>
